import AuthLocalStorage from "../../AuthLocalStorage";
import { AxiosError } from "axios";
import { Modal } from "antd";
import { deleteCookie, setCookie } from ".";
import { NetCatResponseData } from "../interfaces";

export const RemoveTokens = () => {
  AuthLocalStorage.removeUserID();
  AuthLocalStorage.removeRole();
  AuthLocalStorage.removeTargetSelects();
  deleteCookie("token");
  deleteCookie("ClientId");
  deleteCookie("roles");
  deleteCookie("currentUserId");
  window.location.assign("/");
};

export const setTokens = (response: any) => {
  AuthLocalStorage.setUserID(response.client_id.toString());
  AuthLocalStorage.setRole(response.roles);
  setCookie("token", response.token);
  setCookie("ClientId", response.client_id.toString());
  setCookie("roles", response.roles);
  setCookie("currentUserId", response.user_id.toString());
};

export const handleError = (error: AxiosError) => {
  if (
    error.response?.data &&
    error.response!.data !== "request cannot be parsed"
  ) {
    let modal = Modal.error({});
    if (error.code === "ERR_NETWORK") {
      modal.update({
        title: "Network error.",
      });
      setTimeout(modal.destroy, 2 * 1000);
    } else if (error.response) {
      switch (error.response!.status) {
        case 400:
          modal.update({
            title: "Bad request",
            content: error.response.data?.toString(),
          });
          setTimeout(modal.destroy, 2 * 1000);
          break;
        case 401:
          if (error.response.request?.responseURL?.includes("api/user")) {
            modal.update({
              title: "Authorization error",
            });
            setTimeout(modal.destroy, 2 * 1000);
            setTimeout(RemoveTokens, 2 * 1000);
          } else if (
            error.response.request?.responseURL?.includes("api/login")
          ) {
            modal.update({
              title: "Wrong credentials",
            });
            setTimeout(modal.destroy, 2 * 1000);
          } else {
            modal.update({
              title: "Access denied",
            });
            setTimeout(modal.destroy, 2 * 1000);
          }
          break;
        case 403:
          if (error.response.request?.responseURL?.includes("api/user")) {
            modal.update({
              title: "You don't have permission to access",
            });
            setTimeout(modal.destroy, 2 * 1000);
            setTimeout(RemoveTokens, 2 * 1000);
          } else if (error.response.request?.responseURL?.includes("switch")) {
            modal.update({
              title: "You don't have permission to switch token status",
            });
            setTimeout(modal.destroy, 2 * 1000);
          }
          break;
        case 404:
          modal.update({
            title: "Server could not find a requested data",
          });
          setTimeout(modal.destroy, 2 * 1000);
          break;
        case 406:
          modal.update({
            title: `${error.response.data}`,
          });
          setTimeout(modal.destroy, 2 * 1000);
          break;
        case 500:
          modal.update({
            title: "Oops something went wrong. Please try again later",
          });
          setTimeout(modal.destroy, 2 * 1000);
          break;
        default:
          modal.update({
            title: "Oops something went wrong. Please try again later",
          });
          setTimeout(modal.destroy, 2 * 1000);
          break;
      }
    } else {
      console.log(error.message);
    }
  }
};

export const copyToClipboard = (
  result: any,
  copiedStr: string,
  element_id: string,
  top?: number,
  left?: string
) => {
  document.getElementById(`${element_id}`)?.remove();
  document.getElementById(`${element_id}e`)?.remove();
  const el = document.createElement("textarea");
  el.value = copiedStr;
  el.setAttribute("readonly", "");
  el.style.position = "absolute";
  el.style.left = "-9999px";
  document.body.appendChild(el);
  const selected =
    document.getSelection()!.rangeCount > 0
      ? document!.getSelection()!.getRangeAt(0)
      : false;
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
  if (selected) {
    document.getSelection()!.removeAllRanges();
    document.getSelection()!.addRange(selected);
    copiedStr.length > 0
      ? document
          .getElementById(`${result}`)
          ?.insertAdjacentHTML(
            "afterend",
            `<div id="${element_id}" style="top:${top ? top - 300 : 20}px;${
              left ? `left:${left};` : ""
            }">Successfully copied to clipboard</div>`
          )
      : document
          .getElementById("Copy")
          ?.insertAdjacentHTML(
            "afterend",
            `<div id="cpde" style="top:${top ? top - 300 : 20}px;${
              left ? `left:${left};` : ""
            }">Connection string is empty</div>`
          );
    setTimeout((_: any) => {
      document.getElementById(`${element_id}`)?.remove();
      document.getElementById(`${element_id}e`)?.remove();
    }, 1500);
  }
};

export const getOptions = (data: string[]) => {
  return data?.map((x) => {
    return {
      value: x,
      label: x,
    };
  });
};

export const getFilterOptions = (data: string[]) => {
  return data?.map((x) => {
    return {
      text: x,
      value: x,
    };
  });
};

export const getDateFilterOptions = () => {
  const data = [
    { text: "10 minutes", time: 10 },
    { text: "30 minutes", time: 30 },
    { text: "1 hour", time: 60 },
    { text: "1 day", time: 24 * 60 },
  ];

  return data?.map((x) => {
    return {
      text: x.text,
      value: x.time,
    };
  });
};

export const getDropdownOptions = (data: string[]) => {
  return data?.map((x, index) => {
    return {
      key: x,
      label: x,
    };
  });
};

export const sortOptions = (data: any[]) => {
  return data?.sort((a: any, b: any) => {
    return a.value?.localeCompare(b.value);
  });
};

export const onlyUnique = (value: any, index: any, array: string | any[]) => {
  return array.indexOf(value?.length > 0 && value) === index;
};

export const columnSorter = (a: string, b: string) => {
  return a?.localeCompare(b);
};

export const generateConnectionString = (x: NetCatResponseData): string => {
  let relayParts = x.relay_addr.split(":");
  return x.type === "revshell"
    ? `bb revsh-connect --sid ${x.id}`
    : x.type === "proxy"
    ? `bb rev-client --sid ${x.id}`
    : x.type === "mycelium"
    ? `mushroom shell -t ${x.additional_info.target_path.join(",")}`
    : relayParts.length !== 2
    ? ""
    : `GSOCKET_IP=${relayParts[0]} GSOCKET_PORT=${relayParts[1]} gs-netcat -i -s ${x.secret}`;
};

export const sorter = (a: string | any, b: string | any) => {
  return a?.toLowerCase() > b?.toLowerCase()
    ? 1
    : b?.toLowerCase() > a?.toLowerCase()
    ? -1
    : 0;
};

export const setCache = (response: any, cacheName: string) => {
  if ("caches" in window) {
    // Opening given cache and putting our data into it
    caches.open(cacheName).then((cache) => {
      cache.put(window.location.origin, new Response(JSON.stringify(response)));
    });
  }
};

export const getCache = async (cacheName: string) => {
  if (typeof caches === "undefined") return false;

  const cacheStorage = await caches.open(cacheName);
  const cachedResponse = await cacheStorage.match(window.location.origin);

  // If no cache exists
  if (!cachedResponse || !cachedResponse.ok) {
    return [];
  }

  return cachedResponse.json().then((item) => {
    return item;
  });
};

export const clearCacheData = () => {
  caches.keys().then((names) => {
    names.forEach((name) => {
      caches.delete(name);
    });
  });
};
