import { AxiosError } from "axios";
import {CredentialModel, TargetUpdateNote, handleError, TargetNote} from "../shared";
import { Api } from ".";
import { AddCredentialsFromUI } from "../Components/CredentialsPage/models/AddCredentialsUI.model";
import { AlertLevel } from "../Components/TargetHosts/ui/hostAlert";

export class TargetApi {
  getData = async (data: any) => {
    try {
      const response = await Api.post("/api/v1/target-host/list", data).then(
        (response: { data: any }) => {
          return response.data;
        }
      );
      return response;
    } catch (error) {
      handleError(error as AxiosError);
    }
  };

  getTargetList = async () => {
    try {
      const response = await Api.get("/api/v1/target/list", {
        full: true,
      }).then((response: { data: any }) => {
        return response.data;
      });
      return response;
    } catch (error) {
      handleError(error as AxiosError);
    }
  };

  getTargetData = async (id: string) => {
    try {
      const response = await Api.get(`/api/v1/target/${id}`).then(
        (response: { data: any }) => {
          return response.data;
        }
      );
      return response;
    } catch (error) {
      handleError(error as AxiosError);
    }
  };

  getTargetHostsList = async (id: string, network: boolean) => {
    try {
      const response = await Api.get(`/api/v1/target/${id}/host/list`, {
        networks: network,
      }).then((response: { data: any }) => {
        return response.data;
      });
      return response;
    } catch (error) {
      handleError(error as AxiosError);
    }
  };

  getHostDetails = async (id: string) => {
    try {
      const response = await Api.get(`/api/v1/target-host/${id}`).then(
        (response: { data: any }) => {
          return response.data;
        }
      );
      return response;
    } catch (error) {
      handleError(error as AxiosError);
    }
  };

  updateHost = async (params: any) => {
    try {
      let data: any = {
        target_id: params?.target_id,
        comment: params.commentText,
      };
      if (params.silent !== undefined) {
        data = { ...data, silent: params.silent };
      }

      const response = await Api.put(`/api/v1/target-host/${params.id}`, {
        ...data,
      }).then((response: { data: any }) => {
        return response.data;
      });
      return response;
    } catch (error) {
      handleError(error as AxiosError);
    }
  };

  removeById = async (params: any) => {
    try {
      const response = await Api.remove(`/api/v1/target-host/${params}`).then(
        (response) => {
          return response.data;
        }
      );
      return response;
    } catch (error) {
      handleError(error as AxiosError);
    }
  };

  getHostLogs = async (id: string) => {
    try {
      const response = await Api.get(`/api/v1/target-host/${id}/logs`).then(
        (response: { data: any }) => {
          return response.data;
        }
      );
      return response;
    } catch (error) {
      handleError(error as AxiosError);
    }
  };

  getNetworkMap = async (id: string, gateways: any) => {
    try {
      const response = await Api.get(
        `/api/v1/target/${id}/netmap`,
        gateways
      ).then((response: { data: any }) => {
        return response.data;
      });
      return response;
    } catch (error) {
      handleError(error as AxiosError);
    }
  };

  getNotes = async (id: string) => {
    try {
      const response = await Api.get(`/api/v1/target/${id}/notes`).then(
        (response: { data: any }) => {
          return response.data;
        }
      );
      return response;
    } catch (error) {
      handleError(error as AxiosError);
    }
  };

  getHostNotes = async (id: string) => {
    try {
      const response = await Api.get(`/api/v1/target-host/${id}/notes`).then(
        (response: { data: any }) => {
          return response.data;
        }
      );
      return response;
    } catch (error) {
      handleError(error as AxiosError);
    }
  };

  getNoteById = async (target_id: string, note_id: string) => {
    try {
      const response = await Api.get(
        `/api/v1/target/${target_id}/note/${note_id}`
      ).then((response: { data: any }) => {
        return response.data;
      });
      return response;
    } catch (error) {
      handleError(error as AxiosError);
    }
  };

  createNote = async ({
    id,
    data,
  }: {
    id: string;
    data: { content: string; hosts_ids: string[]; title: string, file_path: string, file_name: string };
  }) => {
    try {
      const response = await Api.post(`/api/v1/target/${id}/note`, data).then(
        (response: { data: any }) => {
          return response.data;
        }
      );
      return response;
    } catch (error) {
      handleError(error as AxiosError);
    }
  };

  updateNote = async ({ target_id, note_id, data }: TargetUpdateNote) => {
    const response = await Api.put(
      `/api/v1/target/${target_id}/note/${note_id}`,
      data
    );
    return response;
  };

  deleteNote = async (target_id: string, note_id: string) => {
    try {
      const response = await Api.remove(
        `/api/v1/target/${target_id}/note/${note_id}`
      ).then((response: { data: any }) => {
        return response.data;
      });
      return response;
    } catch (error) {
      handleError(error as AxiosError);
    }
  };

  uploadNoteFile = async (target_id: string, file: any) => {
    try {
      const response = await Api.uploadFile(
        `/api/v1/target/${target_id}/note/upload`,
        file
      ).then((response) => {
        return response;
      });
      return response;
    } catch (error) {
      handleError(error as AxiosError);
    }
  };

  downloadNoteFile = async (target_id: string, note: TargetNote ) => {
      try {
        let url = `/api/v1/target/${target_id}/note/${note.id}/download`;
        return await Api.getFile(url).then((response) => {
          const href = window.URL.createObjectURL(response.data);
          const anchorElement = document.createElement("a");
          anchorElement.href = href;
          anchorElement.download = note.title + ".txt";
          document.body.appendChild(anchorElement);
          anchorElement.click();
          document.body.removeChild(anchorElement);
          window.URL.revokeObjectURL(href);
        });
      } catch (error) {
        handleError(error as AxiosError);
      }
  };

  getTargetCreds = async (target_id: string): Promise<CredentialModel[]> => {
    return await Api.get(`/api/v1/target/${target_id}/credentials`)
      .then((response: { data: CredentialModel[] }) => {
        return response.data;
      })
      .catch((error: AxiosError) => {
        handleError(error);
        return [];
      });
  };

  addTargetCreds = async (
    target_id: string,
    data: AddCredentialsFromUI
  ): Promise<CredentialModel[]> => {
    return await Api.post(`/api/v1/target/${target_id}/credentials`, data)
      .then(() => {
        return this.getTargetCreds(target_id);
      })
      .catch((error: AxiosError) => {
        handleError(error);
        return [];
      });
  };

  getHostCreds = async (host_id: string): Promise<CredentialModel[]> => {
    return await Api.get(`/api/v1/target-host/${host_id}/credentials`)
      .then((response: { data: CredentialModel[] }) => {
        return response.data;
      })
      .catch((error: AxiosError) => {
        handleError(error);
        return [];
      });
  };

  addHostCreds = async (
    host_id: string,
    data: AddCredentialsFromUI
  ): Promise<CredentialModel[]> => {
    return await Api.post(`/api/v1/target-host/${host_id}/credentials`, data)
      .then(() => {
        return this.getHostCreds(host_id);
      })
      .catch((error: AxiosError) => {
        handleError(error);
        return [];
      });
  };

  updateHostAlert = async (
    host_id: string,
    data: {
      alert_level: AlertLevel;
      reason: string;
    }
  ): Promise<any> => {
    return await Api.post(`/api/v1/target-host/${host_id}/alert`, data)
      .then((response) => {
        return response;
      })
      .catch((error: AxiosError) => {
        handleError(error);
        return [];
      });
  };
}
