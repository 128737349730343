import MDEditor from "@uiw/react-md-editor";
import { Empty, Form, Modal, Typography, Tooltip, message } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TargetApi } from "../../Api";
import {
  TargetNote,
  getCookie,
  handleError,
  renderHostLinks,
} from "../../shared";
import AddNoteForm from "./Modules/AddNoteForm";
import EditFormBtns from "./Modules/EditFormBtns";
import EditNoteBtn from "./Modules/EditNoteBtn";
import cls from "./notes.module.css";
import { AxiosError } from "axios";
import { TargetContext } from "../shared";
import React from "react";
import {DownloadOutlined} from "@ant-design/icons";

const TARGET_CLIENT = new TargetApi();
const { Link } = Typography;

const NodeInfo = () => {
  const token = getCookie("token") as string;
  const routeParams = useParams();
  const [note, setNote] = useState<TargetNote>();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState(false);
  const [form] = Form.useForm(undefined);
  const { targetsList } = React.useContext(TargetContext)!;
  const navi = useNavigate();

  const contentTxt = (i: TargetNote ) => `${i.content}`;

  const getNote = async () => {
    setNote(
      await TARGET_CLIENT.getNoteById(
        `${routeParams.id}`,
        `${routeParams.node_id}`
      )
    );
  };

  const deleteNote = () => {
    Modal.confirm({
      title: "Do you really want to delete this Note?",
      onOk: async () => {
        await TARGET_CLIENT.deleteNote(
          `${routeParams.id}`,
          `${routeParams.node_id}`
        );
        navi(`/ui/target/${routeParams.id}/info`);
      },
    });
  };

  const editNode = () => {
    form.validateFields().then(
      async (result) => {
        try {
          await TARGET_CLIENT.updateNote({
            target_id: `${routeParams.id}`,
            note_id: `${note?.id}`,
            data: {
              content: result.content,
              hosts_ids: result.hosts,
              title: result.title,
              version: note!.version + 1,
              file_path: result.file_path,
              file_name: result.file_name,
            },
          }).then((response: any) => {
            if (response?.status === 200) {
              getNote();
              setIsDirty(false);
              message.info("Note saved successfully")
            }
          });
        } catch (error: any) {
          if (error.response?.status === 406) {
            showBlockedModal();
          } else {
            handleError(error as AxiosError);
          }
        }
      },
      (error: any) => {
        console.log(error);
      }
    );
  };

  const saveAndExit = () => {
    form.validateFields().then(
      async (result) => {
        try {
          await TARGET_CLIENT.updateNote({
            target_id: `${routeParams.id}`,
            note_id: `${note?.id}`,
            data: {
              content: result.content,
              hosts_ids: result.hosts,
              title: result.title,
              version: note!.version + 1,
              file_path: result.file_path,
              file_name: result.file_name,
            },
          }).then((response: any) => {
            if (response?.status === 200) {
              getNote();
              clear();
              message.info("Note saved successfully")
            }
          });
        } catch (error: any) {
          if (error.response?.status === 406) {
            showBlockedModal();
          } else {
            handleError(error as AxiosError);
          }
        }
      },
      (error: any) => {
        console.log(error);
      }
    );
  };

  const showBlockedModal = () => {
    Modal.error({
      title: "Note has been updated by someone",
    });
  };

  const clear = () => {
    setIsEdit(false);
    setIsDirty(false);
    form.resetFields();
  };

  const checkClose = () => {
    if (
      isDirty &&
      window.confirm(
        "The page has unsaved data. Are you sure you want to close form?"
      )
    ) {
      setIsEdit(false);
      setIsDirty(false);
    } else if (!isDirty) {
      setIsEdit(false);
    }
  };

  const handleUnload = (e: any) => {
    e.preventDefault();
    return "The page has unsaved data. Are you sure you want to navigate away?";
  };

  const renderNodeContent = () => {
    return isEdit ? (
      <AddNoteForm
        form={form}
        note={note}
        setIsDirty={setIsDirty}
        id={routeParams.id}
      />
    ) : (
      <div className={cls.textEditor} style={{ height: "max-content" }}>
        <h2>{note?.title}</h2>
        <div style={{ marginBottom: "10px" }}>
          Hosts: {renderHostLinks(note ? note.hosts : [])}
        </div>

        { note?.file_url &&  (
          <div style={{ marginBottom: "10px" }}>
            Content file: &nbsp;
            <Link href={note.file_url} target="_blank" rel="noopener noreferrer">
                { note.file_name }
            </Link>
          </div>
        )}

        {
          <MDEditor
            className={cls.textEditorContent}
            preview="preview"
            value={note?.content}
            hideToolbar={true}
          />
        }
      </div>
    );
  };

  useEffect(() => {
    getNote();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeParams]);

  useEffect(() => {
    document.title = `${note?.title} / ${
      targetsList.find((i) => i.id === routeParams.id)?.name
    }/ Note / BartUI `;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [note]);

  useEffect(() => {
    if (!isDirty) return;
    window.addEventListener("beforeunload", handleUnload, { capture: false });
    return () => {
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, [isDirty]);

  if (!token) return null;

  return (
    <div className="messages_table">
      <div className={cls.content}>
        {(note?.file_name && note?.file_name?.length > 0) || (note?.content && note?.content?.length > 0) ? (
          <>
            <div className={cls.nodeContent}>{renderNodeContent()}</div>
            <div className={cls.btns}>
              {isEdit ? (
                <EditFormBtns
                  addNewNote={editNode}
                  saveAndExit={saveAndExit}
                  checkClose={checkClose}
                />
              ) : (
                <>
                  <EditNoteBtn setIsEdit={setIsEdit} deleteNote={deleteNote}/>
                  <a href={'data:text/plain;charset=UTF-8,' + contentTxt(note)}
                     download={note.title + '.txt'}>
                    <Tooltip title="Download content as .txt" color='darkblue'>
                      <DownloadOutlined className={cls.actionBtn} style={{color: '#1677ff'}}/>
                    </Tooltip>
                  </a>
                </>
              )}
            </div>
          </>
        ) : (
          <div
            className={cls.nodesList}
            style={{ marginTop: "20px", width: "90%" }}
          >
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </div>
        )}
      </div>
    </div>
  );
};

export default NodeInfo;
