import { Form, FormInstance, Input, Select, Upload, Button, UploadFile } from "antd";
import { UploadOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from "react";
import cls from "../notes.module.css";

import { TargetNote } from "../../../shared";
import MDEditor from "@uiw/react-md-editor";
import { useTargetState } from "../../TargetsPage/Store";
import { TargetApi} from "../../../Api";

type Props = {
  form: FormInstance<any>;
  note?: TargetNote | undefined;
  setIsDirty: (isDirtyNote: boolean) => void;
  id?: string;
};

const TARGET_CLIENT = new TargetApi();

const AddNoteForm: React.FC<Props> = ({ form, note, setIsDirty, id }) => {
  const [state, actions] = useTargetState();

  const [fileList, setFileList] = useState<UploadFile[]>(
    note?.file_path ? [
      {
        uid: "-1", 
        name: note?.file_name,
        status: "done", 
        url: note?.file_url,
      },
    ] : []);

  const uploadFile = async (componentsData: any) => {
    let formData = new FormData();
    formData.set("file", componentsData.file);
    const result = await TARGET_CLIENT.uploadNoteFile(`${id}`, formData);

    form.setFieldValue("file_path", result?.data.file_path);
    form.setFieldValue("file_name", result?.data.file_name);

    // clean validation errors after file upload
    form.setFields([
      { name: 'file_path', errors: [] },
      { name: 'file_name', errors: [] },
      { name: 'content', errors: [] },
    ]);

    const uploadedFile: UploadFile = {
      uid: "-1", 
      name: result?.data.file_name,
      status: "done", 
      url: result?.data.file_url,
    };
    setFileList([uploadedFile]);
    
    componentsData.onSuccess();
  };

  const handleChange = (info: any) => {
    if (info.file.status === "removed") {
      setFileList([]);
      form.setFieldValue("file_path", "");
      form.setFieldValue("file_name", "");
    }
  }; 
  
  const getHostList = async () => {
    let results = await TARGET_CLIENT.getTargetHostsList(
      `${id}`,
      state.isHostTable
    );
    results && actions.setHosts(results);
  };

  const getOptions = () => {
    return state.hosts?.map((item: any) => {
      return {
        value: item.id,
        label: `${item.hostname} / ${item.external_ip}`,
      };
    });
  };

  useEffect(() => {
    getHostList();
    if (note) {
      form.setFieldValue("title", note?.title);
      form.setFieldValue("content", note?.content);
      form.setFieldValue("file_path", note?.file_path);
      form.setFieldValue("file_name", note?.file_name);

      form.setFieldValue(
        "hosts",
        note?.hosts.map((item: any) => item.id)
      );
    } else {
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [note]);

  const validateContentOrFileRequired = () => ({
    validator(_: any, value: any) {
      if (form.getFieldValue('content') || form.getFieldValue('file_name')) {
        return Promise.resolve();
      }
      return Promise.reject(new Error("Either 'content' or 'content file' required!"));
    },
  });

  return (
    <Form className={cls.noteForm} form={form}>
      <Form.Item name="title" rules={[{ required: true }]} className={cls.noteFormItem}>
        <Input
          placeholder="Enter note title"
        />
      </Form.Item>
      <Form.Item name="hosts" className={cls.noteFormItem}>
        <Select
          placeholder={"Select hosts"}
          mode="multiple"
          options={getOptions()}
          onChange={(value) => {
            setIsDirty(true);
          }}
        />
      </Form.Item>
      <Form.Item name="file_name" hidden><Input /></Form.Item>
      <Form.Item
        name="file_path" rules={[validateContentOrFileRequired]} className={cls.noteFormItem}
      >
        <Upload 
          customRequest={uploadFile}
          fileList={fileList}
          onChange={handleChange}
          maxCount={1} 
          accept=".txt"
        >
          <Button>Upload content file <UploadOutlined className="upload_btn" /></Button>
        </Upload>
      </Form.Item>      
      <Form.Item name="content" className={cls.formItemMce}>
        <MDEditor
          className={cls.textEditor}
          onChange={() => setIsDirty(true)}
          preview="edit"
        />
      </Form.Item>
    </Form>
  );
};

export default AddNoteForm;
