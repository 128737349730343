import { Tooltip, Typography } from 'antd';
import moment from 'moment';

const { Link } = Typography;

type Props = {
    date: Date
}

export const DateCorrector = (props: Props) => {
    let time = Math.round(Math.floor((Date.now() - props.date.getTime())) / (1000 * 60));
    return time < 1 ? <>Now</> : time < 60 ? <>{`${time} min ago`}</> :
        time >= 60 && time <= (24 * 60) ? <>{`${Math.round(time / 60)} hrs ago`}</> :
            <>{moment(props.date).format('MMM DD YYYY, HH:mm')}</>;

}

export const renderHostLinks = (hosts: { id: string, hostname: string }[]) => {
    return <>
        {
            hosts.map((h: { id: string, hostname: string }, index: number) =>
                <Link key={(index + 1) * 1000} href={`/ui/target-host/${h.id}/info`}
                    style={{ marginRight: '5px' }} >
                    {h.hostname}{index !== hosts.length - 1 ? ',' : ''}
                </Link>
            )
        } </>
}


export const renderDate = (date: any) =>
    <Tooltip placement="topLeft" title={new Date(date).toString()}>
        <DateCorrector date={new Date(date)} />
    </Tooltip>


export const date_sorter = (a?: string, b?: string) =>
    (a ? new Date(a).getTime() : new Date('01/01/2000').getTime()) - (b ? new Date(b).getTime() : new Date('01/01/2000').getTime())

export const date_filter = (value: any, date: any) =>
    Math.round(Math.floor((Date.now() - new Date(date).getTime()) / (1000 * 60))) <= value 