import {
  Host,
  SearchTargetsModel,
  columnSorter,
  customNotIncludesFilter,
  date_sorter,
  getCookie,
  getFilterOptions,
  onlyUnique,
  renderDate,
  render_tags,
  sortOptions,
} from "../../../shared";
import { iconFilter, renderTools } from "../../shared";
import { HostStore } from "../Store";
import { ColumnsType } from "antd/es/table";
import { render_actions, render_targets } from "../actions";
import { AlertLevel, host_alert_level } from "./hostAlert";
import { host_link } from "../../NetcatTable/actions";
import { Tooltip } from "antd";

export const Get_target_columns = (
  targetsList?: SearchTargetsModel[],
  hostInfo?: Host[]
) => {
  const roles = getCookie("roles")?.split(",");
  const state = hostInfo ? hostInfo : HostStore.storeState.getState().tabledata;

  const columns = (
    [
      {
        title: "Host name",
        dataIndex: "hostname",
        key: "HostName",
        width: "10%",
        render: (value: string, record: any) =>
          host_link(value, record.target_host_id),
        sorter: (a: { hostname: string }, b: { hostname: string }) =>
          columnSorter(a.hostname, b.hostname),
      },
      {
        title: "Status",
        dataIndex: "alert_level",
        key: "alert_level",
        width: "6%",
        render: (value: AlertLevel, record: any) => {
          return (
            <span style={{ display: "flex", flexDirection: "row" }}>
              {value && value?.length > 0 ? (
                host_alert_level(value, record.id)
              ) : (
                <></>
              )}
              {record.silent ? (
                <Tooltip title={`silent`} color="green">
                  <span style={{ fontSize: "20px" }}>&#129762;</span>
                </Tooltip>
              ) : (
                <></>
              )}
            </span>
          );
        },
      },
      roles?.includes("all-target-hosts")
        ? {
            title: "Client",
            dataIndex: "client",
            key: "client",
            width: "6%",
            filters: [
              { text: "", value: "" },
              ...getFilterOptions(
                state?.map((item: any) => item?.client)?.filter(onlyUnique)
              ),
            ],
            filterSearch: true,
            onFilter: (value: any, record: any) =>
              value.length === 0
                ? record?.client?.length === 0 || !record?.client
                : record?.client?.includes(value),
          }
        : null,
      {
        title: "User name",
        dataIndex: "username",
        key: "username",
        width: "8%",
        sorter: (a: { username: string }, b: { username: string }) =>
          columnSorter(a.username, b.username),
      },
      {
        title: "Tags",
        dataIndex: "tags",
        key: "tags",
        width: "6%",
        filters: sortOptions(
          getFilterOptions(state?.flatMap((x) => x.tags)?.filter(onlyUnique))
        ),
        filterSearch: true,
        render: (value: string[]) => render_tags(value),
        onFilter: (value: any, record: any) => record.tags?.includes(value),
      },
      {
        title: "External IP",
        dataIndex: "external_ip",
        key: "external_ip",
        width: "9%",
      },
      {
        title: "OS",
        dataIndex: "os",
        key: "os",
        ellipsis: {
          showTitle: true,
        },
        width: "7%",
        filters: [
          { text: "", value: "" },
          ...getFilterOptions(
            state?.map((item: any) => item?.os)?.filter(onlyUnique)
          ),
        ],
        filterSearch: true,
        onFilter: (value, record) => record.os === value,
      },
      targetsList !== undefined
        ? {
            title: "Target",
            dataIndex: "target",
            key: "target",
            width: "10%",
            render: (_text: any, record: any) =>
              render_targets(record, targetsList),
            sorter: (a: { target: string }, b: { target: string }) =>
              a.target?.toLowerCase() > b.target?.toLowerCase()
                ? 1
                : b.target?.toLowerCase() > a.target?.toLowerCase()
                ? -1
                : 0,
          }
        : null,
      {
        title: "Comment",
        dataIndex: "comment",
        key: "Comment",
        width: "17%",
      },
      {
        title: "Tools",
        dataIndex: "beacons",
        key: "tools",
        render: (value: any, record: any) =>
          renderTools(
            record.beacons,
            record.remote_sessions,
            record.collection_results
          ),
        filters: iconFilter(state),
        filterSearch: true,
        ellipsis: {
          showTitle: false,
        },
        onFilter: (value: any, record: any) => {
          return HostStore.storeState
            .getState()
            .filters?.tools?.includes("not includes")
            ? customNotIncludesFilter(
                record.beacons,
                value,
                HostStore.storeState.getState().filters?.tools
              ) &&
                customNotIncludesFilter(
                  record.remote_sessions,
                  value,
                  HostStore.storeState.getState().filters?.tools
                )
            : record.beacons?.includes(value) ||
                record.remote_sessions?.includes(value);
        },
        width: "15%",
      },
      {
        title: "Created at",
        dataIndex: "created_at",
        key: "created_at",
        width: "7%",
        render: (created_at: any) => renderDate(created_at),
        sorter: (a: { created_at: any }, b: { created_at: any }) =>
          date_sorter(a.created_at, b.created_at),
      },
      {
        title: "Last active at",
        dataIndex: "last_active_at",
        key: "last_active_at",
        width: "7%",
        render: (last_active_at: any) => renderDate(last_active_at),
        sorter: (a: { last_active_at: any }, b: { last_active_at: any }) =>
          date_sorter(a.last_active_at, b.last_active_at),
      },
      {
        title: "Actions",
        dataIndex: "Actions",
        key: "Actions",
        render: (_text: any, record: any) => render_actions(record),
        width: 150,
        fixed: "right",
      },
    ] as ColumnsType<any>
  ).filter((i) => i !== null);

  return {
    columns: columns,
    column_names: columns.map((item) => item.title!.toString()) as string[],
  };
};
